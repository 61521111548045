<template>
  <b-container v-if="loaded">
    <span>
      <b-button
        variant="link"
        class="fixed-btn-group pt-2 mt-2"
        :to="{name: 'pendingFilings'}"
      >
        <feather-icon type="chevron-left" />
        Back
      </b-button>
      <hr class="w-100 mb-4">
      <h2>{{ title }}</h2>
    </span>
    <b-row>
      <b-col cols="1" class="mt-4">
        <feather-icon type="clipboard" width="75" height="75" />
      </b-col>
      <b-col cols="10" class="ml-2">
        <h4>{{ estimatedCompletion }}</h4>
        {{ dateSubmitted }}
      </b-col>
    </b-row>
    <b-row id="progress-container" class="mb-3 mt-4">
      <b-col cols="12">
        <span class="text-muted">{{ startCase(currentTitle) }}</span>
        <progress-bar
          :data="filingSteps"
          :variant="currentFilingStep == 0 ? 'danger' : 'primary'"
          :show-danger-filing-step-zero="true"
        />
        <p class="mt-2">
          {{ currentDescription }}
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FeatherIcon from '@/components/shared/FeatherIcon'
import _ from 'lodash'
import { currentTimelineFor } from '@/store/modules/orders.store'
import { formatDateString } from '@/common/modules/formatters'
import Masthead from '@/components/shared/Masthead'
import ProgressBar from '@/components/shared/ProgressBar'
import store from '@/store'

export default {
  name: 'FilingStatus',
  components: { Masthead, ProgressBar, FeatherIcon },
  data() {
    return {
      loaded: false,
      company: null,
      order: null,
      orderItem: null,
      imgProps: {
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },
    }
  },
  computed: {
    title() {
      return `Pending ${this.company.name} ${this.type}`
    },
    type() {
      return `${this.orderItem.product.filingName} filing`
    },
    filingSteps() {
      if (!this.orderItem) {
        return []
      } else {
        return currentTimelineFor(this.orderItem)
      }
    },
    currentFilingStep() {
      let result = 0

      for (let index = 0; index < this.filingSteps.length; index++) {
        const element = this.filingSteps[index]
        result = index
        if (element.completed.length == 0) {
          break
        }
      }
      return result
    },
    currentTitle() {
      if (!this.loaded) {
        return ''
      }
      return this.filingSteps[0].name
    },
    currentDescription() {
      if (!this.loaded) {
        return ''
      }
      const handler = 'a filer'
      return this.filingSteps[this.currentFilingStep].components[0].mapping({
        ':company': this.company.name,
        ':filer': `${handler}`,
        ':status': _.startCase(this.orderItem.status),
      })
    },
    estimatedCompletion() {
      let date = new Date(this.orderItem.createdAt)
      date.setDate(date.getDate() + this.orderItem.product.filingMethod.docsIn.days)

      return `Estimated Completion by ${date.toDateString()}`
    },
    dateSubmitted() {
      return `Submitted ${formatDateString(this.order.createdAt)}`
    },
  },
  beforeCreate() {
    this.$store     = store
    this.companies  = this.$store.getters['companies/getAll']
    this.orders     = this.$store.getters['orders/getAll']
  },
  async mounted() {
    const orderId = this.$route.params.orderId
    const orderItemId = this.$route.params.orderItemId
    if (!this.order) {
      this.order = await this.$store.dispatch('orders/fetchId', {
        id: orderId,
      })
    }
    this.orderItem = this.order.orderItems.filter(
      orderItem => orderItem.id === orderItemId
    )[0]

    const companyId = this.order.companyId

    this.company = this.companies.filter(company => company.id === companyId)[0]
    if (!this.company) {
      this.company = await this.$store.dispatch('companies/fetchId', {
        id: companyId,
      })
    }

    this.loaded = true
  },
  methods: {
    startCase: input => _.startCase(input),
  },
}
</script>

<style lang="scss" scoped>
h4 {
  margin-bottom: 0px !important;
}
</style>

