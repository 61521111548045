var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "b-container",
        [
          _c(
            "span",
            [
              _c(
                "b-button",
                {
                  staticClass: "fixed-btn-group pt-2 mt-2",
                  attrs: { variant: "link", to: { name: "pendingFilings" } },
                },
                [
                  _c("feather-icon", { attrs: { type: "chevron-left" } }),
                  _vm._v("\n      Back\n    "),
                ],
                1
              ),
              _c("hr", { staticClass: "w-100 mb-4" }),
              _c("h2", [_vm._v(_vm._s(_vm.title))]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mt-4", attrs: { cols: "1" } },
                [
                  _c("feather-icon", {
                    attrs: { type: "clipboard", width: "75", height: "75" },
                  }),
                ],
                1
              ),
              _c("b-col", { staticClass: "ml-2", attrs: { cols: "10" } }, [
                _c("h4", [_vm._v(_vm._s(_vm.estimatedCompletion))]),
                _vm._v("\n      " + _vm._s(_vm.dateSubmitted) + "\n    "),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3 mt-4", attrs: { id: "progress-container" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.startCase(_vm.currentTitle))),
                  ]),
                  _c("progress-bar", {
                    attrs: {
                      data: _vm.filingSteps,
                      variant:
                        _vm.currentFilingStep == 0 ? "danger" : "primary",
                      "show-danger-filing-step-zero": true,
                    },
                  }),
                  _c("p", { staticClass: "mt-2" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.currentDescription) + "\n      "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }