var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "progress mt-1", attrs: { "data-cy": "progress" } },
    _vm._l(_vm.percentageComplete, function (index) {
      return _c("div", {
        key: index,
        class: _vm.styles,
        style: { width: _vm.findWidthPercentage + "%" },
        attrs: {
          role: "progressbar",
          "aria-valuemin": "0",
          "aria-valuemax": [_vm.totalSteps],
        },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }