<template>
  <div data-cy="progress" class="progress mt-1">
    <div
      v-for="(index) in percentageComplete"
      :key="index"
      :class="styles"
      role="progressbar"
      aria-valuemin="0"
      :aria-valuemax="[totalSteps]"
      :style="{ width: findWidthPercentage + '%' }"
    />
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    animated: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      default: 'primary',
    },
    showDangerFilingStepZero: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      draft: null,
    }
  },
  computed: {
    styles: function() {
      return `progress-bar bg-primary ${this.variant}`
    },
    percentageSplit() {
      return (this.percentageComplete / this.totalSteps) * 100
    },
    percentageComplete() {
      if (this.dataIsEmptyOrNull) {
        return 0
      } else {
        let total = this.data
          .map((step) => step.completed.length)
          .reduce((acc, curr) => acc + curr)

        if (this.showDangerFilingStepZero && total == 0 && this.variant == 'danger') {
          total = 1
        }
        return total
      }
    },
    totalSteps() {
      if (this.dataIsEmptyOrNull) {
        return 1
      } else {
        return this.data
          .map((step) => step.components.length)
          .reduce((acc, curr) => acc + curr)
      }
    },
    findWidthPercentage() {
      if (this.percentageSplit === 0) {
        return 0
      } else {
        return this.percentageSplit / this.percentageComplete
      }
    },
    dataIsEmptyOrNull() {
      return !this.data || this.data.length === 0 || this.data === 0
    },
  },
}
</script>

<style lang="scss">
.progress {
  display: -ms-flexbox !important;
  display: flex !important;
  overflow: hidden !important;
  font-size: 20px !important;
  background-color: #e9ecef !important;
  height: 18px;
}

.primary {
  background-color: rgb(0, 123, 255) !important;
}
.danger {
  background-color: rgb(220, 53, 69) !important;
}

.progress-bar.bg-primary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;

  transition: width 0.6s ease;
  border: #f9fff9 !important;
  border-right-style: solid !important;
  border-right-width: 1px !important;
}
</style>
